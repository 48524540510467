"use client";
import React, { useEffect, useState } from "react";
import useStore from "../../store/useStore";
import Image from "next/image";
import Link from "next/link";
import Sidebar from "./sidebar";

const Header = ({ applyEffect, data, industries: industryData, header }: { applyEffect: any; data: any; industries: any; header: any }) => {
  const [isSidebar, setIsSidebar] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [service, setService] = useState(false);
  const [industries, setIndustries] = useState(false);
  const { currentService, updateCurrentService } = useStore();
  useEffect(() => {
    // setIsOpen(isOpen);
    if (isOpen) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }, [isOpen]);
  const handleSidebar = () => {
    setIsOpen(!isOpen);
    applyEffect();
  };

  const updateCurrentServices = (service: any) => {
    updateCurrentService(service);
  };
  const handleRedirect = (service: any) => {
    if (service._id) {
      updateCurrentServices(service);
    }
    setIsOpen(!isOpen);
    applyEffect();
    return 0;
  };
  return (
    <>
      <div className="flex absolute ">
        <div className={` bg-[#0F1929] text-white absolute  h-screen transition-all duration-300 z-40 ${isOpen ? "w-[425px] md:w-96" : "w-0 overflow-hidden"}`}>
          <div className={`h-full px-0 py-4  border-2 border-[#0F1929] border-r-[#FFFFFF]/10`}>
            <div className={`text-white mt-20  ${isOpen ? "overflow-y-auto" : "w-0 "}`}>
              <div className={`text-white w-full h-[calc(100vh-100px)] pb-24  ${isOpen ? "overflow-y-auto" : ""}`}>
                <div className={`flex justify-between px-4 py-4 my-2 rounded-md  hover:bg-[#D9D9D9]/15 focus:outline-none focus:ring focus:ring-violet-300 ${industries ? "bg-[#D9D9D9]/15" : ""}`} onClick={() => setIndustries(!industries)}>
                  <div className="text-3xl ">Industries</div>
                  <div className="mt-3">
                    {industries ? (
                      <svg width="20" height="20" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.05377 14.1504C1.38775 14.4839 1.84049 14.6713 2.31252 14.6713C2.78455 14.6713 3.23728 14.4839 3.57127 14.1504L13 4.7216L22.4288 14.1504C22.5918 14.3254 22.7885 14.4657 23.007 14.5631C23.2255 14.6604 23.4614 14.7128 23.7005 14.717C23.9397 14.7212 24.1773 14.6772 24.3991 14.5876C24.6209 14.4981 24.8223 14.3647 24.9915 14.1956C25.1606 14.0264 25.294 13.8249 25.3836 13.6031C25.4731 13.3814 25.5171 13.1438 25.5129 12.9046C25.5087 12.6654 25.4564 12.4296 25.359 12.2111C25.2616 11.9926 25.1213 11.7959 24.9463 11.6329L14.2588 0.945354C13.9248 0.611784 13.4721 0.42442 13 0.42442C12.528 0.42442 12.0753 0.611784 11.7413 0.945354L1.05377 11.6329C0.7202 11.9668 0.532837 12.4196 0.532837 12.8916C0.532837 13.3636 0.7202 13.8164 1.05377 14.1504Z" fill="#0997C5" />
                      </svg>
                    ) : (
                      <svg width="20" height="20" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.05365 0.991248C1.38763 0.657678 1.84036 0.470316 2.3124 0.470316C2.78443 0.470316 3.23716 0.657678 3.57115 0.991248L12.9999 10.42L22.4286 0.991248C22.5917 0.816242 22.7884 0.675874 23.0069 0.578518C23.2254 0.481162 23.4612 0.428813 23.7004 0.424594C23.9396 0.420374 24.1771 0.464369 24.3989 0.553957C24.6207 0.643544 24.8222 0.776887 24.9914 0.946032C25.1605 1.11518 25.2939 1.31666 25.3834 1.53845C25.473 1.76025 25.517 1.99782 25.5128 2.23699C25.5086 2.47616 25.4562 2.71203 25.3589 2.93053C25.2615 3.14902 25.1212 3.34568 24.9461 3.50875L14.2586 14.1962C13.9247 14.5298 13.4719 14.7172 12.9999 14.7172C12.5279 14.7172 12.0751 14.5298 11.7411 14.1962L1.05365 3.50875C0.720078 3.17476 0.532715 2.72203 0.532715 2.25C0.532715 1.77797 0.720078 1.32523 1.05365 0.991248Z" fill="#0997C5" />
                      </svg>
                    )}
                  </div>
                </div>
                {industries && (
                  <div className="py-4 px-4">
                    <ul>
                      {industryData?.industries?.map((industry: any, index: any) => (
                        <>
                          <Link onClick={handleRedirect} href="#industries" key={index}>
                            <li className="text-xl my-1 py-2 px-2 rounded-md hover:bg-[#D9D9D9]/15">{industry?.name}</li>
                          </Link>
                          <hr className="h-px bg-[#FFFFFF]/15 border-0 " />
                        </>
                      ))}
                    </ul>
                  </div>
                )}
                <div className={`flex justify-between px-4 py-4 my-2 rounded-md  hover:bg-[#D9D9D9]/15 focus:outline-none focus:ring focus:ring-violet-300 ${service ? "bg-[#D9D9D9]/15" : ""}`} onClick={() => setService(!service)}>
                  <div className="text-3xl ">Services</div>
                  <div className="mt-3">
                    {service ? (
                      <svg width="20" height="20" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.05377 14.1504C1.38775 14.4839 1.84049 14.6713 2.31252 14.6713C2.78455 14.6713 3.23728 14.4839 3.57127 14.1504L13 4.7216L22.4288 14.1504C22.5918 14.3254 22.7885 14.4657 23.007 14.5631C23.2255 14.6604 23.4614 14.7128 23.7005 14.717C23.9397 14.7212 24.1773 14.6772 24.3991 14.5876C24.6209 14.4981 24.8223 14.3647 24.9915 14.1956C25.1606 14.0264 25.294 13.8249 25.3836 13.6031C25.4731 13.3814 25.5171 13.1438 25.5129 12.9046C25.5087 12.6654 25.4564 12.4296 25.359 12.2111C25.2616 11.9926 25.1213 11.7959 24.9463 11.6329L14.2588 0.945354C13.9248 0.611784 13.4721 0.42442 13 0.42442C12.528 0.42442 12.0753 0.611784 11.7413 0.945354L1.05377 11.6329C0.7202 11.9668 0.532837 12.4196 0.532837 12.8916C0.532837 13.3636 0.7202 13.8164 1.05377 14.1504Z" fill="#0997C5" />
                      </svg>
                    ) : (
                      <svg width="20" height="20" viewBox="0 0 26 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M1.05365 0.991248C1.38763 0.657678 1.84036 0.470316 2.3124 0.470316C2.78443 0.470316 3.23716 0.657678 3.57115 0.991248L12.9999 10.42L22.4286 0.991248C22.5917 0.816242 22.7884 0.675874 23.0069 0.578518C23.2254 0.481162 23.4612 0.428813 23.7004 0.424594C23.9396 0.420374 24.1771 0.464369 24.3989 0.553957C24.6207 0.643544 24.8222 0.776887 24.9914 0.946032C25.1605 1.11518 25.2939 1.31666 25.3834 1.53845C25.473 1.76025 25.517 1.99782 25.5128 2.23699C25.5086 2.47616 25.4562 2.71203 25.3589 2.93053C25.2615 3.14902 25.1212 3.34568 24.9461 3.50875L14.2586 14.1962C13.9247 14.5298 13.4719 14.7172 12.9999 14.7172C12.5279 14.7172 12.0751 14.5298 11.7411 14.1962L1.05365 3.50875C0.720078 3.17476 0.532715 2.72203 0.532715 2.25C0.532715 1.77797 0.720078 1.32523 1.05365 0.991248Z" fill="#0997C5" />
                      </svg>
                    )}
                  </div>
                </div>
                {service && (
                  <div className="py-4 px-4 ">
                    <ul>
                      {data?.services?.map((service: any, index: any) => (
                        <>
                          <Link onClick={() => handleRedirect(service)} href="#services" key={index}>
                            <li className="text-xl my-1 py-2 px-2 rounded-md hover:bg-[#D9D9D9]/15">{service.name}</li>
                          </Link>
                          <hr className="h-px bg-[#FFFFFF]/15 border-0 " />
                        </>
                      ))}
                    </ul>
                  </div>
                )}

                <Link onClick={handleRedirect} href="#technologies" className="flex justify-between px-4 py-4 my-2 rounded-md hover:bg-[#D9D9D9]/15">
                  <div className="text-3xl">Technologies</div>
                </Link>
                <Link onClick={handleRedirect} href="#our-approach" className="flex justify-between px-4 py-4 my-2 rounded-md hover:bg-[#D9D9D9]/15">
                  <div className="text-3xl">Our Approach</div>
                </Link>
                <Link onClick={handleRedirect} href="#about-us" className="flex justify-between px-4 py-4 my-2 rounded-md hover:bg-[#D9D9D9]/15">
                  <div className="text-3xl">About Us</div>
                </Link>
                <Link onClick={handleRedirect} href="#location" className="flex justify-between px-4 py-4 my-2 rounded-md hover:bg-[#D9D9D9]/15">
                  <div className="text-3xl">Location</div>
                </Link>
                <Link onClick={handleRedirect} href="#careers" className="flex justify-between px-4 py-4 my-2 rounded-md hover:bg-[#D9D9D9]/15">
                  <div className="text-3xl">Careers</div>
                </Link>
                <Link onClick={handleRedirect} href="#our-team" className="flex justify-between px-4 py-4 my-2 rounded-md hover:bg-[#D9D9D9]/15">
                  <div className="text-3xl">Our Team</div>
                </Link>
              </div>
            </div>
          </div>
          <div className=" absolute bg-[#0F1929] inline-block inset-x-0 bottom-0 text-center border-2 border-[#0F1929] border-r-[#FFFFFF]/10 ">
            <hr className="h-[2px] bg-[#FFFFFF]/15 border-0 " />
            <div className="py-5 ">
              <Link href="#contact-us" onClick={handleRedirect}>
                <button className="border text-xl w-80  border-gray-400 text-white bg-transparent py-3 px-4 rounded-full hover:bg-gray-700 focus:outline-none">Lets connect</button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute top-1 left-1 z-40" onClick={handleSidebar}>
        <div className={`menu-burger ${isOpen ? "open" : ""}`}>
          <div className="menu-line"></div>
          <div className="menu-line"></div>
          <div className="menu-line"></div>
        </div>
      </div>
      <div className="absolute top-[18px] left-20 z-50 h-[40px]">
        <img className="h-[40px]" src={header[0]?.logoUrl} height="100px" alt="assiduous" />
      </div>
    </>
  );
};

export default Header;
