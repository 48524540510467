"use client";

import React from "react";
import { Linkedin } from "lucide-react";
import Link from "next/link";

interface TeamMember {
  name: string;
  designation: string;
  imageUrl: string;
  url: string;
  _key: string;
}

interface TeamData {
  ourTeam: TeamMember[];
  title: string;
}

interface ProfileCardProps {
  name: string;
  role: string;
  imageUrl: string;
  url: string;
  isHoverable?: boolean;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ name, role, imageUrl, isHoverable = false, url }) => {
  return (
    <Link href={url} target="_blank" rel="noopener noreferrer">
      <div className={`group relative overflow-hidden rounded-lg w-72 sm:w-68 h-96 ${isHoverable ? "transition-transform duration-300 hover:shadow-xl hover:ring-2 ring-[#0997C5]" : "ring-1 ring-gray-200"}`}>
        <div className="h-full w-full relative">
          <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
          <img src={imageUrl} alt={`${name}'s profile`} className="h-full w-full object-cover" />
          <div className="absolute bottom-0 left-0 right-0 px-5 py-4">
            <h3 className="text-white text-xl font-semibold mb-1">{name}</h3>
            <p className="text-gray-200 text-sm mb-1">{role}</p>
            {/* Removed Link component from here, using a div instead */}
            <div className="inline-block cursor-pointer">
              <div className="flex items-center justify-center w-9 h-9 rounded-full bg-[#0F1929] group-hover:bg-[#0997C5] transition-colors">
                <div className="flex items-center justify-center w-7 h-7 rounded-full bg-white">
                  <span className="text-gray-900 group-hover:text-[#0997C5] font-semibold text-sm">in</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const OurTeam: React.FC<{ data: TeamData }> = ({ data }) => {
  return (
    <section className="our-team bg-[#0F1929] text-white px-5 pt-24 md:px-16 md:pt-32 lg:px-28 lg:pt-32">
      <h1 id="our-team" className="font-medium text-4xl sm:text-5xl lg:text-5xl xl:text-6xl tracking-tight pt-2 sm:pt-6 mb-12">
        {data.title}
      </h1>
      <div className="flex flex-wrap items-center justify-center gap-8 bg-[#0F1929]">
        {data.ourTeam.map((member) => (
          <ProfileCard key={member._key} url={member.url} name={member.name} role={member.designation} imageUrl={member.imageUrl} isHoverable={true} />
        ))}
      </div>
    </section>
  );
};

export default OurTeam;
